import React from "react";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import HeroReusableV2 from "./HeroReusableV2";

const Hero11 = () => {
  const versionNumber = "102";
  const ColorButton = withStyles(() => ({
    root: {
      width: "100%",
      height: "35px",
    },
  }))(Button);
  return (
    <HeroReusableV2
      ColorButton={ColorButton}
      Animation={"StarChart"}
      headerWithDesc={true}
      versionNumber={versionNumber}
    />
  );
};
export default Hero11;
